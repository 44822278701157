/** * 解决方案  */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/arPunch-solution-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">游戏化思维赋能旅游行业</p>
            <p class="banner-text">以AR打卡玩法为核心，搭配多种产品插件，满足多场景需求</p>
          </div>
      </v-img>
    </v-banner>

      <div class="d-flex justify-center width-per-100 pb-15 pt-15">
        <v-card class="max-width-1200 width-per-100" flat color="transparent">
          <div class="d-flex justify-center" >
            <transition name="slide-fade" appear>
              <v-img style="background-color: transparent"
                    width="400" max-width="400" max-height="260" min-height="260"
                    src="https://h5.ophyer.cn/official_website/other/arPunch-solution-img1.png">
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        size="80"
                        width="8"
                        color="grey lighten-3"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </transition>
            <transition name="slide-fade-reverse" appear>
              <div style="margin-left: 80px;width: 385px;"
                  class="d-flex align-center">
                <div>
                  <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2.7;position: relative;">宣传景区历史文化 <span style="position: absolute;bottom: 0;width: 50px;height: 4px;background: #2B8DFD;"></span></v-card-title>
                  <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 20px;">宣传景区历史文化</v-card-text>
                  <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                    <div class="width-per-100 white-space-pre-wrap">AR寻宝打卡，全息导游对景区进行讲解，搭配历史重现插件，玩家可以在游玩过程中收听完整的景区讲解；在游戏结束时合成宝物获取景区历</div>
                  </v-card-text>
                </div>
              </div>
            </transition>
          </div>
        </v-card>
      </div>


      <div class="d-flex justify-center width-per-100  background-F5F9FD pb-15 pt-15">
        <v-card class="max-width-1200 width-per-100 background-F5F9FD" flat>
          <div class="d-flex justify-center background-F5F9FD iecenter">
            <transition name="slide-fade" appear>
              <div style="margin-right: 80px;width: 385px;"
                  class="d-flex align-center">
                <div>
                  <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2.7;position: relative;">唤醒景区的经济活力 <span style="position: absolute;bottom: 0;width: 50px;height: 4px;background: #2B8DFD;"></span></v-card-title>
                  <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 20px;">景区商家促活解决方案</v-card-text>
                  <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                    <div class="width-per-100 white-space-pre-wrap">采用剧情杀揭秘，将需要导流的商户设置为打卡点，并配置商户对应的优惠券，商户可通过扫描优惠券二维码核销优惠券，促进游客进行二次消</div>
                  </v-card-text>
                </div>
              </div>
            </transition>
            <transition name="slide-fade-reverse" appear>
              <v-img style="background-color: transparent"
                    width="400" max-width="400" max-height="260" min-height="260"
                    src="https://h5.ophyer.cn/official_website/other/arPunch-solution-img2.png">
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        size="80"
                        width="8"
                        color="grey lighten-3"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </transition>
          </div>
        </v-card>
      </div>

      <div class="d-flex justify-center width-per-100 pb-15 pt-15">
        <v-card class="max-width-1200 width-per-100" flat color="transparent">
          <div class="d-flex justify-center">
            <transition name="slide-fade" appear>
              <v-img style="background-color: transparent"
                    width="400" max-width="400" max-height="260" min-height="260"
                    src="https://h5.ophyer.cn/official_website/other/arPunch-solution-img3.png">
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        size="80"
                        width="8"
                        color="grey lighten-3"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </transition>
            <transition name="slide-fade-reverse" appear>
              <div style="margin-left: 80px;width: 385px;"
                  class="d-flex align-center">
                <div>
                  <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2.7;position: relative;">促进景区的二次传播 <span style="position: absolute;bottom: 0;width: 50px;height: 4px;background: #2B8DFD;"></span></v-card-title>
                  <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 20px;">景区宣传方法</v-card-text>
                  <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                    <div class="width-per-100 white-space-pre-wrap">强大内容支撑的核心玩法，配合互动留言墙插件，让景区活动更具话题；通过测试类玩法激发玩家主动分享的欲望，极大的促进了二次传播</div>
                  </v-card-text>
                </div>
              </div>
            </transition>
          </div>
        </v-card>
      </div>




  </v-app>
</template>

<script>

export default {
  name: 'Solution',
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.iecenter{
  display: -ms-flex;
  justify-content: center;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  
}
</style>
